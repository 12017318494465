import React, { useState } from "react";
import { Form, Button, InputNumber, Switch, Radio } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const ProgramDetailsForm = ({ onSubmit }) => {
  const [isConfigurator, setIsConfigurator] = useState(true);
  const [isPack, setIsPack] = useState("business");

  const onIsConfiguratorChange = (value) => setIsConfigurator(!!value);

  const onIsPackChange = (e) => setIsPack(e.target.value);

  const onFinish = (values) => {
    // console.log("Finish succeed");
    onSubmit(values, isConfigurator, isPack);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("Finish failed");
    onSubmit(values);
  };

  const validationRules = [
    {
      required: true,
      message: "Veuillez entrer un nombre.",
    },
    {
      pattern: /^[0-9]+$/,
      message: "Doit être un entier positif.",
    },
  ];

  return (
    <Form
      layout="vertical"
      className="form"
      initialValues={
        process.env.NODE_ENV === "development"
          ? {
            buildingNumber: 3,
            floorNumber: 5,
            houseNumber: 100,
            isConfigurator: true,
            diffHouseNumber: 70,
            pack: "business",
          }
          : { pack: "business" }
      }
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="form-elements"><Form.Item
        label="Bâtiments"
        hasFeedback
        name="buildingNumber"
        rules={validationRules}
      >
        <InputNumber
          type="number"
          pattern="\d*"
          min={1}
          placeholder="Nombre de bâtiment"
        />
      </Form.Item>
        <Form.Item
          label="Étages (hors RdC)"
          hasFeedback
          name="floorNumber"
          rules={validationRules}
        >
          <InputNumber
            type="number"
            pattern="\d*"
            min={1}
            placeholder="Nombre d'étages"
          />
        </Form.Item>
        <Form.Item
          label="Logements"
          hasFeedback
          name="houseNumber"
          rules={validationRules}
        >
          <InputNumber
            type="number"
            pattern="\d*"
            min={1}
            placeholder="Nombre de logements"
          />
        </Form.Item></div>
      <div className="form-elements">
        <Form.Item label="Pack" name="pack">
          <Radio.Group name="pack" onChange={onIsPackChange}>
            <Radio.Button value="access">Access</Radio.Button>
            <Radio.Button value="premium">Premium</Radio.Button>
            <Radio.Button value="business">Business</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {isPack !== "access" ? (
          <div>
            <Form.Item label="Variante" className="form-switch">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div>Option configurateur</div>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked
                  checked={isConfigurator}
                  onChange={onIsConfiguratorChange}
                />
              </div>
            </Form.Item>

            <Form.Item
              label="Logements différents"
              hasFeedback
              name="diffHouseNumber"
              rules={validationRules}
            >
              <InputNumber
                type="number"
                pattern="\d*"
                min={1}
                placeholder="Nombre de logements différents"
              />
            </Form.Item>
          </div>
        ) : null}</div>
      <div className="form-button"><Button
        block="true"
        size="large"
        type="primary"
        htmlType="submit"
        style={{ fontWeight: "700" }}
      >
        Calculer
      </Button></div>
    </Form>
  );
};

export default ProgramDetailsForm;
