const datas = {
  virtualTour: {
    sold: {
      diffHousePrice: 600,
      housePrice: 70,
      coeffPremium: 1,
      coeffBusiness: 1,
    },
    team3D: {
      humanDailyCost: 200,
      houseTime: 0.25,
      layoutTime: 0.25,
      catalogTime: 3,
    },
    teamDev: {
      humanDailyCost: 300,
      deploymentTime: 1,
      integrationTime: 2,
    },
  },
};

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate total time & cost for virtualTour production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
function virtualTourTotalCalculator(diffHouseNumber, houseNumber, isPack) {
  // Convert values to integers
  diffHouseNumber = Number(diffHouseNumber);
  // Error coeff. on cost prices
  const erroRate = 1.1;

  const {
    virtualTourTeam3DTime,
    virtualTourTeam3DCost,
  } = virtualTourSubTotalTeam3D(diffHouseNumber);
  const {
    virtualTourTeamDevTime,
    virtualTourTeamDevCost,
  } = virtualTourSubTotalTeamDev();
  const virtualTourTotalTime = Number(
    Math.ceil(virtualTourTeam3DTime + virtualTourTeamDevTime),
  );
  const virtualTourTotalCost = Number(
    Math.ceil((virtualTourTeam3DCost + virtualTourTeamDevCost) * erroRate),
  );

  // Use different price algorithm if Pack = Premium or Pack = Business
  let virtualTourSoldPrice;
  if (isPack === "business") {
    virtualTourSoldPrice =
      (diffHouseNumber * datas.virtualTour.sold.diffHousePrice +
        houseNumber * datas.virtualTour.sold.housePrice) *
      datas.virtualTour.sold.coeffBusiness;
  } else {
    virtualTourSoldPrice =
      diffHouseNumber *
      datas.virtualTour.sold.diffHousePrice *
      datas.virtualTour.sold.coeffPremium;
  }
  // console.log(virtualTourTotalTime, virtualTourTeam3DTime, virtualTourTeamDevTime);
  return {
    virtualTourTotalTime: virtualTourTotalTime,
    virtualTourTotalCost: virtualTourTotalCost,
    virtualTourSoldPrice: virtualTourSoldPrice,
  };
}

export default virtualTourTotalCalculator;

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate 3D Team time & cost for virtualTour production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––

// Sum function
// ––––––––––––
function virtualTourSubTotalTeam3D(diffHouseNumber) {
  const { houseModTime, houseModCost } = houseCalculator(diffHouseNumber);

  const { layoutModTime, layoutModCost } = layoutCalculator(diffHouseNumber);
  const { catalogModTime, catalogModCost } = catalogCalculator();

  const virtualTourTeam3DTime = houseModTime + layoutModTime + catalogModTime;
  const virtualTourTeam3DCost = houseModCost + layoutModCost + catalogModCost;
  // Return vars
  return {
    virtualTourTeam3DTime: virtualTourTeam3DTime,
    virtualTourTeam3DCost: virtualTourTeam3DCost,
  };
}

// Details functions
// ––––––––––––––––––
function houseCalculator(diffHouseNumber) {
  // House Time Calculator
  const houseModTime = datas.virtualTour.team3D.houseTime * diffHouseNumber;

  // House Cost Calculator
  const houseModCost = houseModTime * datas.virtualTour.team3D.humanDailyCost;
  // console.log(houseModTime, houseModCost);
  // Return vars
  return {
    houseModTime: houseModTime,
    houseModCost: houseModCost,
  };
}

function layoutCalculator(diffHouseNumber) {
  // Layout Time Calculator
  const layoutModTime = datas.virtualTour.team3D.layoutTime * diffHouseNumber;

  // Layout Cost Calculator
  const layoutModCost = layoutModTime * datas.virtualTour.team3D.humanDailyCost;
  //console.log(layoutModTime, layoutModCost);
  // Return vars
  return { layoutModTime: layoutModTime, layoutModCost: layoutModCost };
}

function catalogCalculator() {
  // Catalog Time Calculator
  const catalogModTime = datas.virtualTour.team3D.catalogTime;

  // Catalog Cost Calculator
  const catalogModCost =
    catalogModTime * datas.virtualTour.team3D.humanDailyCost;
  // console.log(catalogModTime, catalogModCost);
  // Return vars
  return { catalogModTime: catalogModTime, catalogModCost: catalogModCost };
}

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate Dev time & cost for virtualTour production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––

// Sum function
// ––––––––––––
function virtualTourSubTotalTeamDev() {
  const { deploymentTime, deploymentCost } = deploymentCalculator();
  const { integrationTime, integrationCost } = integrationCalculator();
  const virtualTourTeamDevTime = deploymentTime + integrationTime;
  const virtualTourTeamDevCost = deploymentCost + integrationCost;

  // Return vars
  return {
    virtualTourTeamDevTime: virtualTourTeamDevTime,
    virtualTourTeamDevCost: virtualTourTeamDevCost,
  };
}

// Details functions
// –––––––––––––––––
function deploymentCalculator() {
  // Time to deploy
  const deploymentTime = datas.virtualTour.teamDev.deploymentTime;

  // Deployment cost
  const deploymentCost =
    deploymentTime * datas.virtualTour.teamDev.humanDailyCost;

  return {
    deploymentTime: deploymentTime,
    deploymentCost: deploymentCost,
  };
}

function integrationCalculator() {
  // Time of assets integration
  const integrationTime = datas.virtualTour.teamDev.integrationTime;

  // Cost of assets integration
  const integrationCost =
    integrationTime * datas.virtualTour.teamDev.humanDailyCost;
  return { integrationTime: integrationTime, integrationCost: integrationCost };
}
