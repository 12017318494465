import React, { Component } from "react";
import maquetteTotalCalculator from "../Functions/ProgramPreviewCalculator";
import configurateurTotalCalculator from "../Functions/ConfiguratorCalculator";
import virtualTourTotalCalculator from "../Functions/VirtualTourCalculator";
import PricesTables from "./PricesTables";
import ProgramDetailsForm from "./ProgramDetailsForm";

class ProjectCaracteristics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  // Erase previous datas in dataSource
  resetDataSources = () => {
    this.setState({ dataSource: null });
  };

  // Clear table loading when updated
  clearLoading = () => {
    this.setState({ loading: false });
  };

  onProgramDetailsChange = async (values, isConfigurator, isPack) => {
    // Don't display table if a value is missing
    let value = null;
    for (value in values) {
      if (values[value] == null || values[value] === "") {
        this.resetDataSources();
        throw Error;
      }
    }

    // console.log(this.state.loading);

    // Log inputs form values
    // console.log("Received values of form: ", values);

    // Trigger table loading when table update
    this.setState({ loading: true });
    setTimeout(this.clearLoading, 300);

    // Calculate vars according to project caracteristics
    // Maquette datas
    const {
      maquetteTotalTime,
      maquetteTotalCost,
      maquetteSoldPrice,
    } = maquetteTotalCalculator(
      values.buildingNumber,
      values.floorNumber,
      values.houseNumber,
    );

    const maquetteSuccessFeesInitialPrice = Math.round(maquetteSoldPrice * 0.5);
    const maquetteSuccessFeesTotalPrice = Math.round(
      maquetteSoldPrice + maquetteSuccessFeesInitialPrice,
    );

    const maquetteCashPricePerHouse = Math.round(
      maquetteSoldPrice / values.houseNumber,
    );

    const maquetteSuccessPricePerHouse = Math.round(
      maquetteSuccessFeesTotalPrice / values.houseNumber,
    );

    const maquetteCashGrossMargin = Math.round(
      maquetteSoldPrice - maquetteTotalCost,
    );
    const maquetteSuccessGrossMargin = Math.round(
      maquetteSuccessFeesTotalPrice - maquetteTotalCost,
    );

    const maquetteCashMarginRate = Math.round(
      (maquetteCashGrossMargin / maquetteSoldPrice) * 100,
    );

    const maquetteSuccessMarginRate = Math.round(
      (maquetteSuccessGrossMargin / maquetteSuccessFeesTotalPrice) * 100,
    );

    // Configurateur datas
    const {
      configurateurTotalTime,
      configurateurTotalCost,
      configurateurSoldPrice,
    } = configurateurTotalCalculator(
      values.diffHouseNumber,
      values.houseNumber,
      isPack,
    );


    const configurateurSuccessFeesInitialPrice = Math.round(
      configurateurSoldPrice * 0.5,
    );
    const configurateurSuccessFeesTotalPrice = Math.round(
      configurateurSoldPrice + configurateurSuccessFeesInitialPrice,
    );

    const configurateurCashPricePerHouse = Math.round(
      configurateurSoldPrice / values.houseNumber,
    );
    const configurateurSuccessPricePerHouse = Math.round(
      configurateurSuccessFeesTotalPrice / values.houseNumber,
    );

    const configurateurCashGrossMargin = Math.round(
      configurateurSoldPrice - configurateurTotalCost,
    );

    const configurateurSuccessGrossMargin = Math.round(
      configurateurSuccessFeesTotalPrice - configurateurTotalCost,
    );

    const configurateurCashMarginRate = Math.round(
      (configurateurCashGrossMargin / configurateurSoldPrice) * 100,
    );

    const configurateurSuccessMarginRate = Math.round(
      (configurateurSuccessGrossMargin / configurateurSuccessFeesTotalPrice) *
      100,
    );

    // Virtual Tour datas
    const {
      virtualTourTotalTime,
      virtualTourTotalCost,
      virtualTourSoldPrice,
    } = virtualTourTotalCalculator(
      values.diffHouseNumber,
      values.houseNumber,
      isPack,
    );

    const virtualTourSuccessFeesInitialPrice = Math.round(
      virtualTourSoldPrice * 0.5,
    );
    const virtualTourSuccessFeesTotalPrice = Math.round(
      virtualTourSoldPrice + virtualTourSuccessFeesInitialPrice,
    );

    const virtualTourCashPricePerHouse = Math.round(
      virtualTourSoldPrice / values.houseNumber,
    );
    const virtualTourSuccessPricePerHouse = Math.round(
      virtualTourSuccessFeesTotalPrice / values.houseNumber,
    );

    const virtualTourCashGrossMargin = Math.round(
      virtualTourSoldPrice - virtualTourTotalCost,
    );

    const virtualTourSuccessGrossMargin = Math.round(
      virtualTourSuccessFeesTotalPrice - virtualTourTotalCost,
    );

    const virtualTourCashMarginRate = Math.round(
      (virtualTourCashGrossMargin / virtualTourSoldPrice) * 100,
    );

    const virtualTourSuccessMarginRate = Math.round(
      (virtualTourSuccessGrossMargin / virtualTourSuccessFeesTotalPrice) * 100,
    );

    // Display configurators datas if "Configurateur" is selected or maquette datas otherwise
    const dataSource =
      isPack === "access"
        ? [
          {
            key: "0",
            product: "Outdoor",
            soldPrice: maquetteSoldPrice,
            discount: 0,
            discountPrice: maquetteSoldPrice,
            cashPricePerHouse: maquetteCashPricePerHouse,
            successPricePerHouse: maquetteSuccessPricePerHouse,
            cashGrossMargin: maquetteCashGrossMargin,
            successGrossMargin: maquetteSuccessGrossMargin,
            prodTime: maquetteTotalTime,
            costPrice: maquetteTotalCost,
            houseNumber: values.houseNumber,
            cashMarginRate: maquetteCashMarginRate,
            successMarginRate: maquetteSuccessMarginRate,
            successInitialPrice: maquetteSuccessFeesInitialPrice,
            successTotalPrice: maquetteSuccessFeesTotalPrice,
          },
        ]
        : isConfigurator
          ? [
            {
              key: "1",
              product: "Outdoor",
              soldPrice: 0,
              discount: 0,
              discountPrice: 0,
              cashPricePerHouse: 0,
              successPricePerHouse: 0,
              cashGrossMargin: 0,
              successGrossMargin: 0,
              prodTime: maquetteTotalTime,
              costPrice: maquetteTotalCost,
              houseNumber: values.houseNumber,
              cashMarginRate: 0,
              successMarginRate: 0,
              successInitialPrice: 0,
              successTotalPrice: 0,
            },
            {
              key: "2",
              product: "Indoor Pro",
              soldPrice: configurateurSoldPrice,
              discount: 0,
              discountPrice: configurateurSoldPrice,
              cashPricePerHouse: configurateurCashPricePerHouse,
              successPricePerHouse: configurateurSuccessPricePerHouse,
              cashGrossMargin: configurateurCashGrossMargin,
              successGrossMargin: configurateurSuccessGrossMargin,
              prodTime: configurateurTotalTime,
              costPrice: configurateurTotalCost,
              houseNumber: values.houseNumber,
              cashMarginRate: configurateurCashMarginRate,
              successMarginRate: configurateurSuccessMarginRate,
              successInitialPrice: configurateurSuccessFeesInitialPrice,
              successTotalPrice: configurateurSuccessFeesTotalPrice,
            },
          ]
          : [
            {
              key: "3",
              product: "Outdoor",
              soldPrice: 0,
              discount: 0,
              discountPrice: 0,
              cashPricePerHouse: 0,
              successPricePerHouse: 0,
              cashGrossMargin: 0,
              successGrossMargin: 0,
              prodTime: maquetteTotalTime,
              costPrice: maquetteTotalCost,
              houseNumber: values.houseNumber,
              cashMarginRate: 0,
              successMarginRate: 0,
              successInitialPrice: 0,
              successTotalPrice: 0,
            },
            {
              key: "4",
              product: "Indoor",
              soldPrice: virtualTourSoldPrice,
              discount: 0,
              discountPrice: virtualTourSoldPrice,
              cashPricePerHouse: virtualTourCashPricePerHouse,
              successPricePerHouse: virtualTourSuccessPricePerHouse,
              cashGrossMargin: virtualTourCashGrossMargin,
              successGrossMargin: virtualTourSuccessGrossMargin,
              prodTime: virtualTourTotalTime,
              costPrice: virtualTourTotalCost,
              houseNumber: values.houseNumber,
              cashMarginRate: virtualTourCashMarginRate,
              successMarginRate: virtualTourSuccessMarginRate,
              successInitialPrice: virtualTourSuccessFeesInitialPrice,
              successTotalPrice: virtualTourSuccessFeesTotalPrice,
            },
          ];

    this.setState({
      dataSource,
    });
    // console.log(this.state.dataSource);
  };

  render() {
    const { dataSource, loading } = this.state;

    return (
      <React.Fragment>
        <ProgramDetailsForm onSubmit={this.onProgramDetailsChange} />

        <div style={{ margin: "8em auto 2em" }}>
          {dataSource ? (
            <PricesTables data={dataSource} loading={loading} />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
export default ProjectCaracteristics;
