const datas = {
  maquette: {
    sold: {
      basePrice: 10000,
      extraBuildingPrice: 800,
      extraFloorPrice: 150,
      housePrice: 20,
      coeff: 1,
    },
    team3D: {
      humanDailyCost: 200,
      environmentTime: 5,
      extraEnvironementTime: 1,
      buildingTime: 5,
      extraBuildingTime: 2,
      settingsTime: 2,
      extraSettingsTime: 1,
      floorFrames: 30,
      frameUnitCost: 0.55,
      frameRenderTime: 0.0012,
    },
    teamDev: {
      humanDailyCost: 300,
      deploymentTime: 2,
      infraCost: 1000,
    },
  },
};

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate total time & cost for Maquette 3D production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
function maquetteTotalCalculator(buildingNumber, floorNumber, houseNumber) {
  // Convert values to integers
  buildingNumber = Number(buildingNumber);
  floorNumber = Number(floorNumber);
  // Error coeff. on cost prices
  const erroRate = 1.1;

  const { maquetteTeam3DTime, maquetteTeam3DCost } = maquetteSubTotalTeam3D(
    buildingNumber,
    floorNumber,
  );
  const {
    maquetteTeamDevTime,
    maquetteTeamDevCost,
  } = maquetteSubTotalTeamDev();
  const maquetteTotalTime = Number(
    Math.ceil(maquetteTeam3DTime + maquetteTeamDevTime),
  );
  const maquetteTotalCost = Number(
    Math.ceil((maquetteTeam3DCost + maquetteTeamDevCost) * erroRate),
  );
  const maquetteSoldPrice = maquetteSoldCalculator(
    buildingNumber,
    floorNumber,
    houseNumber,
  );
  // console.log(maquetteTotalTime, maquetteTeam3DTime, maquetteTeamDevTime);
  return {
    maquetteTotalTime: maquetteTotalTime,
    maquetteTotalCost: maquetteTotalCost,
    maquetteSoldPrice: maquetteSoldPrice,
  };
}

export default maquetteTotalCalculator;

// –––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate total sold price for Maquette 3D product
// –––––––––––––––––––––––––––––––––––––––––––––––––––
function maquetteSoldCalculator(buildingNumber, floorNumber, houseNumber) {
  let extraBuildingPrice = 0;
  let extraFloorPrice = 0;

  if (buildingNumber > 1) {
    extraBuildingPrice =
      (buildingNumber - 1) * datas.maquette.sold.extraBuildingPrice;
  } else {
    extraBuildingPrice = 0;
  }

  if (floorNumber > 4) {
    extraFloorPrice = (floorNumber - 4) * datas.maquette.sold.extraFloorPrice;
  } else {
    extraFloorPrice = 0;
  }

  const maquetteSoldPrice =
    (datas.maquette.sold.basePrice +
      extraBuildingPrice +
      extraFloorPrice +
      houseNumber * datas.maquette.sold.housePrice) *
    datas.maquette.sold.coeff;

  return maquetteSoldPrice;
}

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate 3D Team time & cost for Maquette 3D production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––

// Sum function
// ––––––––––––
function maquetteSubTotalTeam3D(buildingNumber, floorNumber) {
  const { environmentModTime, environmentModCost } = environmentCalculator(
    buildingNumber,
  );
  const { buildingModTime, buildingModCost } = buildingCalculator(
    buildingNumber,
    floorNumber,
  );
  const { settingsModTime, settingsModCost } = settingsCalculator(
    buildingNumber,
  );
  const { renderTime, renderCost } = renderCalculator(
    buildingNumber,
    floorNumber,
  );

  const maquetteTeam3DTime =
    buildingModTime + environmentModTime / 2 + settingsModTime + renderTime;
  const maquetteTeam3DCost =
    environmentModCost + buildingModCost + settingsModCost + renderCost;
  // Return vars
  return {
    maquetteTeam3DTime: maquetteTeam3DTime,
    maquetteTeam3DCost: maquetteTeam3DCost,
  };
}

// Details functions
// ––––––––––––––––––
function environmentCalculator(buildingNumber) {
  // Environment Time Calculator
  let extraTime = 0;
  if (buildingNumber > 1) {
    extraTime = datas.maquette.team3D.extraEnvironementTime;
  } else {
    extraTime = 0;
  }

  const environmentModTime = datas.maquette.team3D.environmentTime + extraTime;

  // Environment Cost Calculator
  const environmentModCost =
    environmentModTime * datas.maquette.team3D.humanDailyCost;
  // console.log(
  //   "Environment time: " + environmentModTime,
  //   "Environment cost: " + environmentModCost
  // );
  // Return vars
  return {
    environmentModTime: environmentModTime,
    environmentModCost: environmentModCost,
  };
}

function buildingCalculator(buildingNumber, floorNumber) {
  // Building Time Calculator
  let extraFloors = 0;

  if (floorNumber > 4) {
    extraFloors = (floorNumber - 4) * 0.2;
  } else {
    extraFloors = 0;
  }

  const buildingModTime =
    datas.maquette.team3D.buildingTime +
    datas.maquette.team3D.extraBuildingTime * (buildingNumber - 1) +
    extraFloors;

  // Building Cost Calculator
  const buildingModCost =
    buildingModTime * datas.maquette.team3D.humanDailyCost;
  // console.log(
  //   "Building time: " + buildingModTime,
  //   "Building cost: " + buildingModCost
  // );
  // Return vars
  return { buildingModTime: buildingModTime, buildingModCost: buildingModCost };
}

function settingsCalculator(buildingNumber) {
  // Settings Time Calculator
  let extraTime = 0;
  if (buildingNumber > 1) {
    extraTime = datas.maquette.team3D.extraSettingsTime;
  } else {
    extraTime = 0;
  }

  const settingsModTime = datas.maquette.team3D.settingsTime + extraTime;

  // Settings Cost Calculator
  const settingsModCost =
    settingsModTime * datas.maquette.team3D.humanDailyCost;
  // console.log(settingsModTime, settingsModCost);
  // Return vars
  return { settingsModTime: settingsModTime, settingsModCost: settingsModCost };
}

function renderCalculator(buildingNumber, floorNumber) {
  // Total frames number
  const framesNumber =
    buildingNumber *
      (datas.maquette.team3D.floorFrames * (floorNumber + 2) + 1) +
    datas.maquette.team3D.floorFrames;

  // Time to render
  const renderTime = datas.maquette.team3D.frameRenderTime * framesNumber;

  // Render cost
  const renderCost = datas.maquette.team3D.frameUnitCost * framesNumber;
  // console.log(renderTime, renderCost);
  // Return vars
  return { renderTime: renderTime, renderCost: renderCost };
}

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate Dev time & cost for Maquette 3D production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––

// Sum function
// ––––––––––––
function maquetteSubTotalTeamDev() {
  const { deploymentTime, deploymentCost } = deploymentCalculator();
  const infraCost = infraCalculator();
  const maquetteTeamDevTime = deploymentTime;
  const maquetteTeamDevCost = deploymentCost + infraCost;

  // Return vars
  return {
    maquetteTeamDevTime: maquetteTeamDevTime,
    maquetteTeamDevCost: maquetteTeamDevCost,
  };
}

// Details functions
// –––––––––––––––––
function deploymentCalculator() {
  // Time to deploy
  const deploymentTime = datas.maquette.teamDev.deploymentTime;

  // Deployment cost
  const deploymentCost = deploymentTime * datas.maquette.teamDev.humanDailyCost;

  return {
    deploymentTime: deploymentTime,
    deploymentCost: deploymentCost,
  };
}

function infraCalculator() {
  // Cost of infrastructure
  const infraCost = datas.maquette.teamDev.infraCost;
  return infraCost;
}
