import React, { Component } from "react";
import { Table, Typography } from "antd";
import { EditableCell, EditableRow } from "./EditableCell";

const { Text } = Typography;

class CashSaleTable extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Produit",
        dataIndex: "product",
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span style={{ fontWeight: "500" }}>{value}</span>;
        },
      },
      {
        title: "Prix de vente",
        dataIndex: "soldPrice",
        width: "12%",
        editable: true,
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")}€</span>;
        },
      },
      {
        title: "Remise",
        dataIndex: "discount",
        width: "8%",
        editable: true,
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")}%</span>;
        },
      },
      {
        title: "Prix remisé",
        dataIndex: "discountPrice",
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")}€</span>;
        },
      },
      {
        title: "Coût /logement",
        dataIndex: "cashPricePerHouse",
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")}€</span>;
        },
      },
      {
        title: "Temps production",
        dataIndex: "prodTime",
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")} jours (ouvrés)</span>;
        },
      },
      {
        title: "Coût de revient",
        dataIndex: "costPrice",
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")}€</span>;
        },
      },
      {
        title: "Marges",
        children: [
          {
            title: "Marge brute",
            dataIndex: "cashGrossMargin",
            render: (value, row, index) => {
              // add thousand separator and number unit
              return <span>{value.toLocaleString("fr-FR")}€</span>;
            },
          },
          {
            title: "Taux de marge",
            dataIndex: "cashMarginRate",
            render: (value, row, index) => {
              // add thousand separator and number unit
              return <span>{value.toLocaleString("fr-FR")}%</span>;
            },
          },
        ],
      },
    ];
    this.state = {
      dataSource: this.props.data,
    };
  }

  // Force la mise à jour des données
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({ dataSource: this.props.data });
    }
  }

  calculateData = (row) => {
    const discountPrice = row.soldPrice * (1 - row.discount / 100);
    return {
      discountPrice: Math.ceil(discountPrice),
      cashPricePerHouse: Math.round(discountPrice / row.houseNumber),
      cashGrossMargin: Math.round(discountPrice - row.costPrice),
      cashMarginRate: Math.round(
        ((discountPrice - row.costPrice) / discountPrice) * 100,
      ),
    };
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      ...this.calculateData(row),
    });
    this.setState({ dataSource: newData });
    // console.log(newData);
  };

  render() {
    const { dataSource } = this.state;
    // Editable Cell
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    // Sum row
    const summary = (pageData) => {
      let totalSoldPrice = 0;
      let totalDiscount = 0;
      let totalDiscountPrice = 0;
      let totalCashPricePerHouse = 0;
      let totalProdTime = 0;
      let totalCostPrice = 0;
      let totalCashMarginRate = 0;
      let totalCashGrossMargin = 0;
      let totalMaquetteProdTime = 0;
      let totalConfigurateurProdTime = 0;

      // Check if Configurateur prod time exist
      try {
        totalConfigurateurProdTime = pageData[1].prodTime;
      } catch (error) {
        totalConfigurateurProdTime = null;
      }

      // Set variable for Maquette prod time
      totalMaquetteProdTime = pageData[0].prodTime;

      // Display in total the biggest prod time from products
      if (totalConfigurateurProdTime == null) {
        // console.log("Only Maquette prod time exists");
        totalProdTime = Number(totalMaquetteProdTime);
      } else if (totalMaquetteProdTime > totalConfigurateurProdTime) {
        // console.log("Maquette prod time is bigger");
        totalProdTime = Number(totalMaquetteProdTime);
      } else {
        // console.log("Configurateur/VirtualTour prod time is bigger");
        totalProdTime = Number(totalConfigurateurProdTime);
      }

      pageData.forEach(
        ({
          soldPrice,
          discount,
          discountPrice,
          cashPricePerHouse,
          // prodTime,
          costPrice,
        }) => {
          totalSoldPrice += Number(soldPrice);
          totalDiscountPrice += Number(discountPrice);
          totalCashPricePerHouse += Number(cashPricePerHouse);
          // totalProdTime += Number(prodTime);
          totalCostPrice += Number(costPrice);
          totalDiscount = Math.round(
            (1 - totalDiscountPrice / totalSoldPrice) * 100,
          );
          totalCashGrossMargin = Math.round(
            totalDiscountPrice - totalCostPrice,
          );
          totalCashMarginRate = Math.round(
            (totalCashGrossMargin / totalDiscountPrice) * 100,
          );
        },
      );

      return (
        <>
          <tr>
            <th>Total</th>
            <td>
              <Text className="strong-data">
                {totalSoldPrice.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalDiscount.toLocaleString("fr-FR")}%
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalDiscountPrice.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalCashPricePerHouse.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalProdTime.toLocaleString("fr-FR")} jours (ouvrés)
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalCostPrice.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalCashGrossMargin.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalCashMarginRate.toLocaleString("fr-FR")}%
              </Text>
            </td>
          </tr>
        </>
      );
    };
    // Table
    return (
      <Table
        tableLayout={"auto"}
        className={"tableCashCSS"}
        pagination={false}
        loading={this.props.loading}
        scroll={{ x: "100%" }}
        rowClassName={() => "editable-row"}
        components={components}
        columns={columns}
        dataSource={dataSource}
        bordered
        summary={summary}
      />
    );
  }
}

export default CashSaleTable;
