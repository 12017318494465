import React, { Component } from "react";
import { Table, Typography } from "antd";
import { EditableCell, EditableRow } from "./EditableCell";

const { Text } = Typography;

class SuccessFeesSaleTable extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Produit",
        dataIndex: "product",
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span style={{ fontWeight: "500" }}>{value}</span>;
        },
      },
      {
        title: "Prix de vente",
        dataIndex: "soldPrice",
        width: "12%",
        editable: true,
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")}€</span>;
        },
      },
      {
        title: "Remise",
        dataIndex: "discount",
        width: "8%",
        editable: true,
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")}%</span>;
        },
      },
      {
        title: "Prix remisé",
        dataIndex: "discountPrice",
        render: (value, row, index) => {
          // add thousand separator and number unit
          return <span>{value.toLocaleString("fr-FR")}€</span>;
        },
      },
      {
        title: "Vente en Success Fees",
        children: [
          {
            title: "Montant initial",
            dataIndex: "successInitialPrice",
            render: (value, row, index) => {
              // add thousand separator and number unit
              return <span>{value.toLocaleString("fr-FR")}€</span>;
            },
          },
          {
            title: "Montant total",
            dataIndex: "successTotalPrice",
            render: (value, row, index) => {
              // add thousand separator and number unit
              return <span>{value.toLocaleString("fr-FR")}€</span>;
            },
          },
          {
            title: "Coût /logement",
            dataIndex: "successPricePerHouse",
            render: (value, row, index) => {
              // add thousand separator and number unit
              return <span>{value.toLocaleString("fr-FR")}€</span>;
            },
          },
        ],
      },
      {
        title: "Marges",
        children: [
          {
            title: "Marge brute",
            dataIndex: "successGrossMargin",
            render: (value, row, index) => {
              // add thousand separator and number unit
              return <span>{value.toLocaleString("fr-FR")}€</span>;
            },
          },
          {
            title: "Taux de marge",
            dataIndex: "successMarginRate",
            render: (value, row, index) => {
              // add thousand separator and number unit
              return <span>{value.toLocaleString("fr-FR")}%</span>;
            },
          },
        ],
      },
    ];
    this.state = {
      dataSource: this.props.data,
    };
  }

  // Force la mise à jour des données
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({ dataSource: this.props.data });
    }
  }

  calculateData = row => {
    const discountPrice = row.soldPrice * (1 - row.discount / 100);
    return {
      discountPrice: Math.ceil(discountPrice),
      successInitialPrice: Math.round(discountPrice * 0.5),
      successTotalPrice: Math.round(discountPrice * (1 + 0.5)),
      successPricePerHouse: Math.round(
        (discountPrice * (1 + 0.5)) / row.houseNumber,
      ),
      successGrossMargin: Math.round(discountPrice * (1 + 0.5) - row.costPrice),
      successMarginRate: Math.round(
        ((discountPrice * (1 + 0.5) - row.costPrice) /
          (discountPrice * (1 + 0.5))) *
          100,
      ),
    };
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      ...this.calculateData(row),
    });
    this.setState({ dataSource: newData });
    // console.log(newData);
  };

  render() {
    const { dataSource } = this.state;
    // Editable Cell
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    // Sum row
    const summary = pageData => {
      let totalSoldPrice = 0;
      let totalDiscount = 0;
      let totalDiscountPrice = 0;
      let totalSuccessPricePerHouse = 0;
      let totalCostPrice = 0;
      let totalSuccessInitialPrice = 0;
      let totalSuccessTotalPrice = 0;
      let totalSuccessMarginRate = 0;
      let totalSuccessGrossMargin = 0;

      pageData.forEach(
        ({
          soldPrice,
          discount,
          discountPrice,
          successPricePerHouse,
          costPrice,
          successInitialPrice,
          successTotalPrice,
        }) => {
          totalSoldPrice += Number(soldPrice);
          totalDiscountPrice += Number(discountPrice);
          totalSuccessPricePerHouse += Number(successPricePerHouse);
          totalCostPrice += Number(costPrice);
          totalDiscount = Math.round(
            (1 - totalDiscountPrice / totalSoldPrice) * 100,
          );
          totalSuccessInitialPrice += Number(successInitialPrice);
          totalSuccessTotalPrice += Number(successTotalPrice);
          totalSuccessGrossMargin = Math.round(
            totalSuccessTotalPrice - totalCostPrice,
          );
          totalSuccessMarginRate = Math.round(
            (totalSuccessGrossMargin / totalSuccessTotalPrice) * 100,
          );
        },
      );

      return (
        <>
          <tr>
            <th>Total</th>
            <td>
              <Text className="strong-data">
                {totalSoldPrice.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalDiscount.toLocaleString("fr-FR")}%
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalDiscountPrice.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalSuccessInitialPrice.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalSuccessTotalPrice.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalSuccessPricePerHouse.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalSuccessGrossMargin.toLocaleString("fr-FR")}€
              </Text>
            </td>
            <td>
              <Text className="strong-data">
                {totalSuccessMarginRate.toLocaleString("fr-FR")}%
              </Text>
            </td>
          </tr>
        </>
      );
    };
    // Table
    return (
      <Table
        tableLayout={"auto"}
        className={"tableSuccessCSS"}
        pagination={false}
        loading={this.props.loading}
        scroll={{ x: "100%" }}
        rowClassName={() => "editable-row"}
        components={components}
        columns={columns}
        dataSource={dataSource}
        bordered
        summary={summary}
      />
    );
  }
}

export default SuccessFeesSaleTable;
