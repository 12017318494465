import React from "react";
import { Layout } from "antd";
import logo from "./logo_black@2x.png";
import "./App.css";
import ProjectCaracteristics from "./Components/ProjectCaracteristics";
import Emoji from "./Components/Emoji";

const { Header, Content } = Layout;

const appVersion = process.env.REACT_APP_VERSION;

function App() {
  console.log("App Version: " + appVersion);
  return (
    <div className="App">
      <Header className="App-header">
        <img className="App-logo" src={logo} alt="logo" />

        <span style={{ textAlign: "right" }}>
          <span style={{ padding: "0 4px" }}>v{appVersion}</span>
          <Emoji symbol="🎉" />
        </span>
      </Header>
      <Content className="App-content">
        <ProjectCaracteristics />
      </Content>
    </div>
  );
}

export default App;
