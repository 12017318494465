import React, { Component } from "react";
import CashSaleTable from "./CashSaleTable";
import SuccessFeesSaleTable from "./SuccessFeesSaleTable";

class PricesTables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: this.props.data,
    };
  }

  // Force la mise à jour des données
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({ dataSource: this.props.data });
    }
  }

  render() {
    const { dataSource } = this.state;

    // Table
    return (
      <React.Fragment>
        <div style={{ margin: "4em auto" }}>
          <CashSaleTable data={dataSource} />
        </div>
        <div style={{ margin: "4em auto" }}>
          <SuccessFeesSaleTable data={dataSource} />
        </div>
      </React.Fragment>
    );
  }
}

export default PricesTables;
