const datas = {
  configurateur: {
    sold: {
      housePriceTo30: 650,
      housePriceTo50: 500,
      housePriceTo100: 450,
      housePriceTo150: 400,
      housePriceTo200: 350,
      housePriceToMore: 325,
      diffHousePrice: 900,
      coeffPremium: 1,
      coeffBusiness: 1,
    },
    team3D: {
      humanDailyCost: 200,
      houseTime: 0.25,
      layoutTime: 0.25,
      catalogTime: 3,
    },
    teamDev: {
      humanDailyCost: 300,
      deploymentTime: 2,
      integrationTime: 3,
    },
  },
};

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate total time & cost for Configurateur production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
function configurateurTotalCalculator(diffHouseNumber, houseNumber, isPack) {
  // Convert values to integers
  diffHouseNumber = Number(diffHouseNumber);
  // Error coeff. on cost prices
  const erroRate = 1.1;

  const {
    configurateurTeam3DTime,
    configurateurTeam3DCost,
  } = configurateurSubTotalTeam3D(diffHouseNumber);
  const {
    configurateurTeamDevTime,
    configurateurTeamDevCost,
  } = configurateurSubTotalTeamDev();
  const configurateurTotalTime = Number(
    Math.ceil(configurateurTeam3DTime + configurateurTeamDevTime),
  );
  const configurateurTotalCost = Number(
    Math.ceil((configurateurTeam3DCost + configurateurTeamDevCost) * erroRate),
  );

  let configurateurSoldPrice;
  if (isPack === "business") {
    if (houseNumber <= 30) {
      configurateurSoldPrice = houseNumber * datas.configurateur.sold.housePriceTo30 *
        datas.configurateur.sold.coeffBusiness;
    }
    else if (houseNumber > 30 && houseNumber <= 50) {
      configurateurSoldPrice = houseNumber * datas.configurateur.sold.housePriceTo50 *
        datas.configurateur.sold.coeffBusiness;
    }
    else if (houseNumber > 50 && houseNumber <= 100) {
      configurateurSoldPrice = houseNumber * datas.configurateur.sold.housePriceTo100 *
        datas.configurateur.sold.coeffBusiness;
    }
    else if (houseNumber > 100 && houseNumber <= 150) {
      configurateurSoldPrice = houseNumber * datas.configurateur.sold.housePriceTo150 *
        datas.configurateur.sold.coeffBusiness;
    }
    else if (houseNumber > 150 && houseNumber <= 200) {
      configurateurSoldPrice = houseNumber * datas.configurateur.sold.housePriceTo200 *
        datas.configurateur.sold.coeffBusiness;
    }
    else if (houseNumber > 200) {
      configurateurSoldPrice = houseNumber * datas.configurateur.sold.housePriceToMore *
        datas.configurateur.sold.coeffBusiness;
    }
  } else {
    configurateurSoldPrice = diffHouseNumber * datas.configurateur.sold.diffHousePrice * datas.configurateur.sold.coeffPremium;
  }

  // console.log(configurateurTotalTime, configurateurTeam3DTime, configurateurTeamDevTime);
  return {
    configurateurTotalTime: configurateurTotalTime,
    configurateurTotalCost: configurateurTotalCost,
    configurateurSoldPrice: configurateurSoldPrice,
  };
}

export default configurateurTotalCalculator;

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate 3D Team time & cost for Configurateur production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––

// Sum function
// ––––––––––––
function configurateurSubTotalTeam3D(diffHouseNumber) {
  const { houseModTime, houseModCost } = houseCalculator(diffHouseNumber);

  const { layoutModTime, layoutModCost } = layoutCalculator(diffHouseNumber);
  const { catalogModTime, catalogModCost } = catalogCalculator();

  const configurateurTeam3DTime = houseModTime + layoutModTime + catalogModTime;
  const configurateurTeam3DCost = houseModCost + layoutModCost + catalogModCost;
  // Return vars
  return {
    configurateurTeam3DTime: configurateurTeam3DTime,
    configurateurTeam3DCost: configurateurTeam3DCost,
  };
}

// Details functions
// ––––––––––––––––––
function houseCalculator(diffHouseNumber) {
  // House Time Calculator
  const houseModTime = datas.configurateur.team3D.houseTime * diffHouseNumber;

  // House Cost Calculator
  const houseModCost = houseModTime * datas.configurateur.team3D.humanDailyCost;
  // console.log(houseModTime, houseModCost);
  // Return vars
  return {
    houseModTime: houseModTime,
    houseModCost: houseModCost,
  };
}

function layoutCalculator(diffHouseNumber) {
  // Layout Time Calculator
  const layoutModTime = datas.configurateur.team3D.layoutTime * diffHouseNumber;

  // Layout Cost Calculator
  const layoutModCost =
    layoutModTime * datas.configurateur.team3D.humanDailyCost;
  //console.log(layoutModTime, layoutModCost);
  // Return vars
  return { layoutModTime: layoutModTime, layoutModCost: layoutModCost };
}

function catalogCalculator() {
  // Catalog Time Calculator
  const catalogModTime = datas.configurateur.team3D.catalogTime;

  // Catalog Cost Calculator
  const catalogModCost =
    catalogModTime * datas.configurateur.team3D.humanDailyCost;
  // console.log(catalogModTime, catalogModCost);
  // Return vars
  return { catalogModTime: catalogModTime, catalogModCost: catalogModCost };
}

// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––
// Calculate Dev time & cost for Configurateur production
// ––––––––––––––––––––––––––––––––––––––––––––––––––––––––

// Sum function
// ––––––––––––
function configurateurSubTotalTeamDev() {
  const { deploymentTime, deploymentCost } = deploymentCalculator();
  const { integrationTime, integrationCost } = integrationCalculator();
  const configurateurTeamDevTime = deploymentTime + integrationTime;
  const configurateurTeamDevCost = deploymentCost + integrationCost;

  // Return vars
  return {
    configurateurTeamDevTime: configurateurTeamDevTime,
    configurateurTeamDevCost: configurateurTeamDevCost,
  };
}

// Details functions
// –––––––––––––––––
function deploymentCalculator() {
  // Time to deploy
  const deploymentTime = datas.configurateur.teamDev.deploymentTime;

  // Deployment cost
  const deploymentCost =
    deploymentTime * datas.configurateur.teamDev.humanDailyCost;

  return {
    deploymentTime: deploymentTime,
    deploymentCost: deploymentCost,
  };
}

function integrationCalculator() {
  // Time of assets integration
  const integrationTime = datas.configurateur.teamDev.integrationTime;

  // Cost of assets integration
  const integrationCost =
    integrationTime * datas.configurateur.teamDev.humanDailyCost;
  return { integrationTime: integrationTime, integrationCost: integrationCost };
}
